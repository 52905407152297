<template>
  <div class="detail">
    <!-- 课程banner -->
    <detailHeader :courseData="state.courseData" :dis_id="state.dis_id" @bindShare="bindShare" :source="state.source"></detailHeader>
    <template v-if="state.courseData.type !== 2 && state.courseData.type !== 3 && state.courseData.type !== 4">
      <!-- 课程信息 -->
      <detailInfo :courseData="state.courseData" :groupData="state.groupData" :goodsDetail="state.goodsDetail"></detailInfo>
      <!-- 购买须知 拼团规则 合集课程选择 -->
      <detailFeatures :courseData="state.courseData" :groupData="state.groupData" :coursesData="state.coursesData" @bindHjConfirm="bindHjConfirm" :source="state.source"></detailFeatures>
    </template>

    <!-- 去参团 -->
    <detailGroup :courseData="state.courseData" :groupData="state.groupData" :userInfo="state.userInfo" :joinData="state.joinData" v-if="!state.buyData.is_buy && state.joinData.length > 0"></detailGroup>
    <!-- 论文查重码课程激活码 -->
    <detailCode :courseData="state.courseData" :buyData="state.buyData" v-if="state.courseData.active_type == 0 || state.courseData.active_type == 1"></detailCode>
    <!-- 课程简介等 -->
    <detailTabs :is_buy="state.is_buy" :courseData="state.courseData" :groupData="state.groupData" :coursesData="state.coursesData" v-if="state.courseData.type !== 2 && state.courseData.type !== 3 && state.courseData.type !== 4" ref="tabs" :source="state.source"></detailTabs>
    <!-- 面授选择 -->
    <detailFace :courseData="state.courseData" ref="binddetailFace" @getBuyStatus="getBuyStatus"></detailFace>
    <!-- 底部按钮 -->
    <detailFooter :courseData="state.courseData" :groupData="state.groupData" :buyData="state.buyData" :gu_id="state.gu_id" :dis_id="state.dis_id" :userInfo="state.userInfo" @bindSharePop="bindSharePop" @refreshData="onRefreshData" v-if="state.source.indexOf('xcx') === -1" :HjcourseData="state.HjcourseData"></detailFooter>
    <!-- 分享弹窗 -->
    <detailShare :shareShow="state.shareShow" @bindShare="bindShare" :type="state.shareType"></detailShare>
    <!-- 推广按钮 -->
    <detailSpread :source="state.source"></detailSpread>
  </div>
</template>

<script>
import {
  reactive,
  onMounted,
  getCurrentInstance,
  watchEffect,
  ref,
  watch,
  onBeforeUnmount
} from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { commitAddShopId, getStore, setStore } from '../../utils/util'
import { useStore } from 'vuex'
import detailHeader from '../../components/detail/header.vue'
import detailInfo from '../../components/detail/info.vue'
import detailTabs from '../../components/detail/tabs.vue'
import detailFeatures from '../../components/detail/features.vue'
import detailGroup from '../../components/detail/group.vue'
import detailFace from '../../components/detail/face.vue'
import detailFooter from '../../components/detail/footer.vue'
import detailShare from '../../components/detail/share.vue'
import detailCode from '../../components/detail/code.vue'
import detailSpread from '../../components/detail/spread.vue'
import WxShare from '../../utils/wx_share'

export default {
  name: 'detail',
  components: {
    detailHeader,
    detailInfo,
    detailTabs,
    detailFeatures,
    detailGroup,
    detailFace,
    detailFooter,
    detailShare,
    detailCode,
    detailSpread
  },
  setup() {
    const { proxy } = getCurrentInstance()
    const binddetailFace = ref()
    const tabs = ref()
    const $route = useRoute()
    const $router = useRouter()
    const time = new Date()
    const store = useStore()
    const state = reactive({
      courseData: [], //课程详情
      coursesData: [], //合集课程列表
      HjcourseData: [], //合集壳子数据
      groupData: [], //拼团数据
      buyData: [],
      dis_id: '', //课程ID
      gu_id: '0', //拼团ID586
      source: '0', //页面来源
      shareShow: false, //分享弹窗
      shareType: '', //弹窗类型
      userInfo: '', //用户信息
      joinData: [], //参团列表
      is_buy: false,
      ly: '',
      url: ''
    })

    watch(
      () => $route.path,
      (to, from) => {
        if (to.indexOf('/index') !== 0) {
          // 请空滚动缓存
          store.commit('globalData/addHomeScrollTop', '')
        }
      }
    )

    watchEffect(() => {
      // 用户信息监听
      if (store.state.userInfo.userInfo) {
        state.userInfo = store.state.userInfo.userInfo
      }
    })

    onMounted(() => {
      state.dis_id = $route.params.dis_id
      if ($route.params.gu_id) {
        state.gu_id = $route.params.gu_id
      }

      // 来源小程序
      if ($route.params.source) {
        state.source = $route.params.source
      }

      let _urlHref = window.location.href,
        matchRes = _urlHref.match(/(\?|\&)ly=[\w]+\/?/)
      if (matchRes) {
        // matchRes[0] && (state.ly = matchRes[0].replace(/(\?|\&)ly=|\//g, ''), state.url = _urlHref)
        store.commit(
          'globalData/addLy',
          matchRes[0].replace(/(\?|\&)ly=|\//g, '')
        )
        store.commit('globalData/addUrl', _urlHref)
      }

      let userInfo = getStore('userInfo')
      if (userInfo) {
        store.commit('userInfo/addUser', userInfo)
        state.userInfo = userInfo
      } else {
        state.userInfo = ''
      }

      getdata() //获取数据
    })

    onBeforeUnmount(() => {
      let obj = store.getters['globalData/getShopInfo']
      if (typeof obj.title != 'undefined') {
        $('title').text(obj.title)
        $("meta[name='description']").attr('content', obj.description)
        WxShare({
          shareTitle: obj.title || '华图小店',
          shareDesc: obj.description || '欢迎来到华图小店~',
          shareUrl: window.location.href,
          shareImg: ''
        })
      } else {
        $('title').text('华图小店')
        $("meta[name='description']").attr('content', '欢迎来到华图小店~')
        WxShare()
      }
    })

    // 普通分享弹窗
    const bindShare = () => {
      state.shareShow = !state.shareShow
      if (state.shareShow == true) {
        state.shareType = ''
      }
    }

    // 拼团分享弹窗
    const bindSharePop = () => {
      state.shareType = 'group'
      state.shareShow = !state.shareShow
    }

    // 获取数据
    const getdata = (shop_id) => {
      proxy.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: '加载中...'
      })

      // 区分合集课程
      let url = ''
      if (shop_id && state.source.indexOf('xcx') === -1) {
        url = '/api/course/detail/' + state.dis_id + '/' + shop_id
      } else if (state.dis_id == 0 && !shop_id && $route.query.dis_id) {
        let tmp_shop_id = getStore('shop_id') || 'n'
        url = '/api/course/detail/' + $route.query.dis_id + '/' + tmp_shop_id
      } else {
        url = '/api/course/detail/' + state.dis_id + '/' + state.source
      }

      proxy.$http
        .get(url)
        .then((res) => {
          // console.log("课程详情===>", res.data)
          const body = res.data.data
          proxy.$toast.clear()
          if (res.data.code == '200') {
            /**新神2的课程（/course/detail接口返回有Url）跳转到M站，其他保持原有逻辑 */
            if (body.url) {
              window.location.href = body.url
            } else {
              if (body.course) {
                setStore('shop_id', body.course.shop_id)
                commitAddShopId(store, proxy, body.course.shop_id)
              }
              if (body.course.type == '7') {
                // 合集课程信息
                state.HjcourseData = body.course
                state.courseData = body.first_course
                state.courseData.status = body.course.status
                state.courseData.group_status = body.course.group_status
                state.courseData.title = body.course.title
                state.courseData.shop_id = state.HjcourseData.shop_id
                state.coursesData = body.courses
                if (
                  body.course.group_status == 1 &&
                  state.HjcourseData.group_buy.id
                ) {
                  state.courseData.group_buy.id =
                    state.HjcourseData.group_buy.id
                }
              } else if (state.HjcourseData.type == '7') {
                // 合集切换
                state.courseData = body.course
                state.courseData.type = state.HjcourseData.type
                state.courseData.status = state.HjcourseData.status
                state.courseData.group_status = state.HjcourseData.group_status
                state.courseData.shop_id = state.HjcourseData.shop_id
                state.courseData.title = state.HjcourseData.title
                if (
                  body.course.group_status == 1 &&
                  state.HjcourseData.group_buy.id
                ) {
                  state.courseData.group_buy.id =
                    state.HjcourseData.group_buy.id
                }
              } else {
                //普通课程
                state.courseData = body.course
                state.HjcourseData = body.course
              }

              // 课程信息 标题，图片，描述放在 title description 中用于微信分享卡片展示用
              let showPrice = state.courseData.group_buy
                ? state.courseData.group_buy.price ||
                  state.courseData.group_buy.group_price
                : state.courseData.price
              $('title').text(state.courseData.title)
              $("meta[name='description']").attr(
                'content',
                `【华图小店】钜惠价格${showPrice}元起`
              )
              WxShare({
                shareTitle: state.courseData.title,
                shareDesc: `【华图小店】钜惠价格${showPrice}元起`,
                shareImg: state.courseData.thumb
              })
              // 普通课程开售-结束时间
              if (state.courseData.sale_start_time) {
                state.courseData.sale_start_time =
                  state.courseData.sale_start_time * 1000 - time
              }

              if (state.courseData.sale_end_time) {
                state.courseData.sale_end_time =
                  state.courseData.sale_end_time * 1000 - time
              }

              //普通课程拼团课程数据//合集课程拼团课程数据
              if (state.courseData.group_buy) {
                state.groupData = state.courseData.group_buy
                state.groupData.group_start_time =
                  state.groupData.group_start_time * 1000 - time // 当前团购结束时间
                state.groupData.group_end_time =
                  state.groupData.group_end_time * 1000 - time // 当前团购结束时间
              }

              // 登录的用户获取是否购买记录
              if (
                state.userInfo !== '' &&
                state.courseData.platform !== 4 &&
                state.courseData.platform !== 5 &&
                state.userInfo.expires_in * 1000 - time > 0
              ) {
                // 拼团课程增加拼团活动ID
                if (body.course.group_buy) {
                  getBuy(state.courseData.id, state.courseData.group_buy.id) //获取购买记录
                } else {
                  getBuy(state.courseData.id) //获取购买记录
                }
              }

              // 面授课程
              if (
                state.courseData.type == 2 ||
                state.courseData.type == 3 ||
                state.courseData.type == 4
              ) {
                binddetailFace.value.getClassLocation(
                  state.courseData.courseid,
                  state.courseData.platform
                ) //获取地区
                binddetailFace.value.getTeacher(
                  state.courseData.courseid,
                  state.courseData.platform
                ) //获取简介
              }

              // 领取课程
              // hadReceive 表示该用户是否已经领取了课程
              state.courseData.hadReceive =
                store.state.globalData.receive_id != -1
              // is_receive 表示该课程是否被领取了
              state.courseData.is_receive =
                state.courseData.id == store.state.globalData.receive_id

              //富文本图片自适应
              let content = state.courseData.intro
              content = content
                .replace(/style="[^"]+"/gi, '')
                .replace(/style='[^']+'/gi, '')
              content = content
                .replace(/width="[^"]+"/gi, '')
                .replace(/width='[^']+'/gi, '')
              content = content
                .replace(/height="[^"]+"/gi, '')
                .replace(/height='[^']+'/gi, '')
              content = content.replace(
                /\<img/gi,
                '<img style="max-width:100% !important;height:auto !important;vertical-align: top !important;"'
              )
              state.courseData.intro = content.replace(
                /\<p/gi,
                '<p style="font-size:14px;"'
              )
            }
          } else {
            proxy.$toast(res.data.message)
          }
        })
        .catch((error) => console.log(error))
    }

    // 面授课获取购买记录
    const getBuyStatus = (id) => {
      console.log('1')
      if (
        state.userInfo !== '' &&
        state.userInfo.expires_in * 1000 - time > 0
      ) {
        state.courseData.id = id
        getBuy(id)
      }
    }

    // 获取购买和拼团数据
    const getBuy = (id, hd_id) => {
      console.log(1)
      // 区分拼团和普通课程
      let url = ''
      if (hd_id) {
        url = '/api/course/is_buy/' + id + '/' + state.gu_id + '/' + hd_id
      } else {
        url = '/api/course/is_buy/' + id + '/' + state.gu_id + '/0'
      }

      proxy.$http
        .get(url)
        .then((res) => {
          // console.log("课程购买数据===>", res.data)
          const body = res.data.data

          if (res.data.code == '200') {
            // 参加过其他团则加载该团信息
            if (
              body.group_buy &&
              body.group_buy.joined_other_pid &&
              state.gu_id == '0'
            ) {
              state.gu_id = body.group_buy.joined_other_pid
              getBuy(id, state.courseData.group_buy.id)
            } else {
              state.buyData = body
              if (body.group_buy) {
                state.groupData.group_start_time =
                  body.group_buy.group_start_time * 1000 - time // 当前团购结束时间
                state.groupData.group_end_time =
                  body.group_buy.group_end_time * 1000 - time // 当前团购结束时间
                state.groupData.join_user = body.group_buy.join_user
                state.groupData.is_join = body.group_buy.is_join

                // 合集课程取合集壳子的拼团ID
                if (state.HjcourseData.type == 7) {
                  state.groupData.id = state.HjcourseData.group_buy.id
                }

                getGroup() //参团列表
              }
              if ('is_buy' in body) {
                state.is_buy = body.is_buy
              }
            }
          } else {
            proxy.$toast(body.message)
          }
        })
        .catch((error) => console.log(error))
    }

    // 获取参团列表
    const getGroup = () => {
      if (
        state.groupData.id === undefined ||
        state.groupData.id == 'undefined' ||
        state.groupData.id === null
      ) {
        return
      }
      proxy.$http
        .get('/api/group/list/' + state.groupData.id + '/' + state.gu_id)
        .then((res) => {
          // console.log("参团列表===>", res.data)
          const body = res.data.data

          if (res.data.code == '200') {
            if (body.group_users !== '') {
              state.joinData = body.group_users
              let time = new Date()

              for (let i in state.joinData) {
                state.joinData[i].endtime =
                  state.joinData[i].expired_time * 1000 - time
              }
            }
          } else {
            proxy.$toast(res.data.message)
          }
        })
        .catch((error) => console.log(error))
    }

    // 合集切换
    const bindHjConfirm = (id) => {
      state.dis_id = id
      tabs.value.active = 0
      getdata(state.courseData.shop_id) //获取数据
    }

    // 领取课程后重新刷新数据
    const onRefreshData = () => {
      getdata()
    }

    return {
      state,
      binddetailFace,
      tabs,
      getdata,
      bindShare,
      bindSharePop,
      bindHjConfirm,
      getBuyStatus,
      onRefreshData
    }
  }
}
</script>

<style scoped lang="scss" src="./index.scss"></style>
